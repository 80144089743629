<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { salesAnalyticsDonutChart } from "./data";
import { reqMethods } from '../../../state/helpers';
import axios from 'axios';

// import { LMap, LMarker, LTileLayer, LIcon } from "@vue-leaflet/vue-leaflet";
// import { latLng } from "leaflet";

// import { alertData } from "./dataReceived";
// import { sosData } from "./sosData";


/**
 * Saas Dashboard Component
 */
export default {
  page: {
    title: "Tableau de bord",
  },
  components: {
    Layout,
    PageHeader,
    // LMap,
    // LTileLayer,
    // LMarker,
    // LIcon
  },
  data() {
    return {
      title: "Tableau de bord",
      salesAnalyticsDonutChart: salesAnalyticsDonutChart,
      // ChatData: ChatData,
      alertData: [],
      sosData: [],
      interventionData: [],
      reponseData: [],
      loggedUser: {

      },
      visibleTab: "sos",
      items: [

      ],
      submitted: false,
      chat: {
        message: "",
      },
      // zoom: 4,
      // maxZoom: 18,
      // center: latLng(10, 1),
      // url: 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      // staticAnchor: [16, 37],
      // markers: [
      //   {
      //     id: "m1",
      //     position: { lat: 6.505, lng: 1.09 },
      //     // draggable: true,
      //     // visible: true
      //   },
      //   {
      //     id: "m2",
      //     position: { lat: 7.8905, lng: 1.09 },
      //     // draggable: true,
      //     // visible: true
      //   },
      //   {
      //     id: "m3",
      //     position: { lat: 6.005, lng: 1.09 },
      //     // draggable: true,
      //     // visible: true
      //   },
      //   {
      //     id: "m4",
      //     position: { lat: 6.7605, lng: 1.09 },
      //     // draggable: true,
      //     // visible: true
      //   }
      // ]
    };
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations: {
    chat: {
      message: {
        required: helpers.withMessage("Message is required", required),
      },
    },
  },
  methods: {
    ...reqMethods,
    async getStats() {
      const formData = new FormData();
      formData.append('day', new Date().getDate());
      formData.append('month', new Date().getMonth() + 1);
      formData.append('year', new Date().getFullYear());
      try {
        const response = axios.post('https://api.alcit.sims-technologie.com/api/v1/alert/alert-spontaneous/statthematic/', formData,
          {
            headers: {
              'Authorization': localStorage.getItem('token'),
              'Content-Type': 'multipart/form-data',
            },
          });
        console.log(response.data);
      } catch (error) {
        console.log(error);
      }
    },
    getReceivedAlertList() {
      const that = this;
      this.getRequest('/alert/alert-spontaneous/?filterThematique=NORMAL')
        .then(
          function (response) {
            response.data.forEach(element => {
              if (element.thematic.name == 'SOS' && element.is_read == false) {
                that.sosData.push(element)
              }
            });
            response.data.forEach(element => {
              if (element.thematic.name != 'SOS' && element.is_read == false) {
                that.alertData.push(element)
              }
            });
          },
          function (error) {
            that.fetchError = error;
          }
        )
    },
    getAlerteResponse() {
      const that = this;
      this.getRequest('/alert/alert/reply')
        .then(
          function (response) {
            response.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            response.data.forEach(element => {
              if (element.pertinence === 0) {
                that.reponseData.push(element);
              }
            });
          },
          function (error) {
            that.reqError = error;
          }
        );
    },
    getIntervList() {
      const that = this;
      this.getRequest('/intervention/intervention/')
        .then(
          function (response) {
            response.data.forEach(element => {
              if (element.status === 1 || element.status === 2) {
                that.interventionData.push(element)
              }
            }
            )
          },
          function (error) {
            that.reqError = error;
          }
        )
    },
    afficherDetails(alert) {
      this.$router.push({ path: `/sosDetail/${alert.id}` });
    },

    afficherDetailsAlerteRecu(alert) {
      this.$router.push({ path: `/receivedAlertDetail/${alert.id}` });
    },

    afficherDetailsResponse(alert) {
      this.$router.push({ path: `/replyDetails/${alert.id}` });
    },
    onChangedVisible(visible) {
      this.visibleTab = visible;
    }
  },
  mounted() {
    this.getReceivedAlertList();
    this.getStats();
    this.getAlerteResponse();
    this.getIntervList();
    this.loggedUser.nom = localStorage.getItem('nom');
    this.loggedUser.prenom = localStorage.getItem('prenom');
    this.loggedUser.serial_number = localStorage.getItem('serial_number');
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-9">
                <div class="d-flex">
                  <div class="flex-grow-1 align-self-center">
                    <div class="text-muted">
                      <h2 class="mb-2">Bienvenue sur Alerte Citoyenne</h2>
                      <p>Votre sécurité sans compromis</p>
                      <h4><strong class="text-success">Centre d'Intelligence Anti-Criminels</strong></h4>
                    </div>
                  </div>
                </div>
              </div>



              <div class="col-lg-3 d-none d-lg-block">
                <div class="d-flex" style="align-content: flex-end">
                  <div class="me-3">
                    <img src="@/assets/images/users/avatar.jpg" alt class="avatar-md rounded-circle img-thumbnail" />
                  </div>
                  <div class="flex-grow-1 align-self-center">
                    <div class="text-muted">
                      <p class="mb-2">Utilisateur:</p>
                      <h5 class="mb-1"> {{ loggedUser.nom }} {{ loggedUser.prenom }} {{ loggedUser.serial_number }} </h5>
                      <p class="mb-0 text-success">Agent de traitement </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
      </div>
    </div>
    <div class="row">

      <div class="col-xl-12">
        <div class="row">
          <div class="col-sm-3">
            <div class="card bg-soft bg-secondary" @click="onChangedVisible('sos')">
              <div class="card-body">
                <div class="d-flex align-items-center mb-3">
                  <div class="avatar-xs me-3">
                    <span class="
                        avatar-title
                        rounded-circle
                        bg-soft bg-white
                        text-warning
                        font-size-18
                      ">
                      <i class="bx bx-archive-in"></i>
                    </span>
                  </div>
                  <h5 class="font-size-14 mb-0 text-white">SOS</h5>
                </div>
                <div class="text-muted mt-4">
                  <h3 class="text-dark">
                    {{ sosData.length }}
                  </h3>

                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="card  bg-secondary" @click="onChangedVisible('alert')">
              <div class="card-body">
                <div class="d-flex align-items-center mb-3">
                  <div class="avatar-xs me-3">
                    <span class="
                        avatar-title
                        rounded-circle
                        bg-soft bg-white
                        text-warning
                        font-size-18
                      ">
                      <i class="bx bx-archive-in"></i>
                    </span>
                  </div>
                  <h5 class="font-size-14 mb-0 text-white">Alertes reçues</h5>
                </div>
                <div class="text-muted mt-4">
                  <h3 class="text-dark">
                    {{ alertData.length }}
                  </h3>

                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-3">
            <div class="card bg-secondary" @click="onChangedVisible('response')">
              <div class="card-body">
                <div class="d-flex align-items-center mb-3">
                  <div class="avatar-xs me-3">
                    <span class="
                        avatar-title
                        rounded-circle
                        bg-soft bg-white
                        text-primary
                        font-size-18
                      ">
                      <i class="bx bx-archive-out"></i>
                    </span>
                  </div>
                  <h5 class="font-size-14 mb-0 text-white">Reponses reçues</h5>
                </div>
                <div class="text-muted mt-4">
                  <h3 class="text-dark">
                    {{ reponseData.length }}
                  </h3>

                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-3">
            <div class="card bg-secondary" @click="onChangedVisible('intervention')">
              <div class="card-body">
                <div class="d-flex align-items-center mb-3">
                  <div class="avatar-xs me-3">
                    <span class="
                        avatar-title
                        rounded-circle
                        bg-soft bg-white
                        text-success
                        font-size-18
                      ">
                      <i class="bx bx-purchase-tag-alt"></i>
                    </span>
                  </div>
                  <h5 class="font-size-14 mb-0 text-white">Interventions en cours</h5>
                </div>
                <div class="text-muted mt-4">
                  <h3 class="text-dark">
                    {{ interventionData.length }}
                  </h3>


                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
    </div>

    <div class="row">
      <div class="card bg-soft" v-if="visibleTab == 'sos'">
        <div class="card-body">
          <h4 class="card-title mb-4">SOS reçus</h4>
          <div class="table-responsive">
            <table class="table table-nowrap table-centered mb-0 align-middle">
              <thead class="table-secondary">
                <tr>
                  <th>Référence</th>
                  <th>Auteur</th>
                  <th>Téléphone</th>
                  <th scope="col">Thématique</th>
                  <th scope="col">Pertinence</th>
                  <th scope="col">Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="alert in sosData" :key=alert.id>
                  <td> {{ alert.short_code }} </td>
                  <td> {{ alert.sender.name }} </td>
                  <td> {{ alert.sender.telephone }} </td>
                  <td> {{ alert.thematic.name }} </td>
                  <td>
                    <span class="badge font-size-11 m-1" :class="{
                      'bg-success': `${alert.pertinence}` == 'PERTINENT',
                      'bg-danger': `${alert.pertinence}` == 'NON_PERTINENT',
                      'bg-info': `${alert.pertinence}` == 'DECISIVE',
                      'bg-primary': `${alert.pertinence}` == 'NORMAL',
                    }">
                      {{ alert.pertinence }}
                    </span>
                  </td>
                  <td> {{ alert.created_at.split('T')[0] }} </td>
                  <td>
                    <button @click="afficherDetails(alert)" class="btn btn-info btn-sm me-2 w-xs">
                      Détails
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="card bg-soft " v-if="visibleTab == 'alert'">
        <div class="card-body">
          <h4 class="card-title mb-4">Liste des alertes reçues</h4>
          <div class="table-responsive mt-3">
            <table class="table table-nowrap table-centered mb-0 align-middle">
              <thead class="table-secondary">
                <tr>
                  <th>Référence</th>
                  <th>Auteur</th>
                  <th>Téléphone</th>
                  <th scope="col">Thématique</th>
                  <th scope="col">Pertinence</th>
                  <th scope="col">Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="alert in alertData" :key=alert.id>
                  <td> {{ alert.short_code }} </td>
                  <td> {{ alert.sender.name }} </td>
                  <td> {{ alert.sender.telephone }} </td>
                  <td> {{ alert.thematic.name }} </td>
                  <td>
                    <span class="badge font-size-11 m-1" :class="{
                      'bg-success': `${alert.pertinence}` == 'PERTINENT',
                      'bg-danger': `${alert.pertinence}` == 'NON_PERTINENT',
                      'bg-info': `${alert.pertinence}` == 'DECISIVE',
                      'bg-primary': `${alert.pertinence}` == 'NORMAL',
                    }">
                      {{ alert.pertinence }}
                    </span>
                  </td>
                  <td> {{ alert.created_at.split('T')[0] }} </td>
                  <td>
                    <button @click="afficherDetailsAlerteRecu(alert)" class="btn btn-info btn-sm me-2 w-xs">
                      Détails
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="card bg-soft" v-if="visibleTab == 'intervention'">
        <div class="card-body">
          <h4 class="card-title mb-4">Liste des interventions en cours</h4>
          <div class="table-responsive mt-3">
            <table class="table table-nowrap table-centered mb-0 align-middle" v-if="interventionData.length > 0">
              <thead class="table-secondary">
                <tr>
                  <th scope="col">Titre</th>
                  <th>Agent affecté</th>
                  <th scope="col">Date</th>
                  <th scope="col">Statut</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="inter in interventionData" :key=inter.id>
                  <td> {{ inter.title }}</td>
                  <td> {{ inter.agent?.username }} </td>
                  <td> {{ inter.created_at.split('T')[0] }} </td>
                  <td>
                    <span v-if="inter.status == 1" class="badge font-size-11 m-1 bg-danger">
                      En attente
                    </span>
                    <span v-if="inter.status == 2" class="badge font-size-11 m-1 bg-danger">
                      En cours
                    </span>
                    <span v-if="inter.status == 4" class="badge font-size-11 m-1 bg-success">
                      Terminée
                    </span>
                  </td>
                  <td>
                    <button @click="$router.push({ path: `/interventionProgress/${inter.id}` })"
                      class="btn btn-warning btn-sm me-2 w-xs">details</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="container" v-if="interventionData.length == 0">
            <div class="row text-center">
              <img src="https://api.alcit.sims-technologie.com/media/frontutils/empty.jpeg"
                style="height: 300px; width: auto; margin: auto;" alt="">
              <h3>Aucune intervention en cours</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="card bg-soft" v-if="visibleTab == 'response'">
        <div class="card-body">
          <h4 class="card-title mb-4">Reponses aux alertes envoyées</h4>
          <div class="table-responsive mt-3">
            <div class="table-responsive mt-3">
              <table class="table table-nowrap table-centered mb-0 align-middle">
                <thead class="table-secondary">
                  <tr>
                    <th>Référence</th>
                    <th>Auteur</th>
                    <th>Téléphone</th>
                    <th scope="col">Pertinence</th>
                    <th scope="col">Date</th>
                    <th>Action</th>



                  </tr>
                </thead>
                <tbody>
                  <tr v-for="reponse in reponseData" :key=reponse.id>
                    <td> {{ reponse.short_code }} </td>
                    <td> {{ reponse.citizen.name }} </td>
                    <td> {{ reponse.citizen.telephone }} </td>

                    <td>
                      <span class="badge font-size-11 m-1" :class="{
                        'bg-primary': `${reponse.pertinence}` == 0,
                      }">
                        Normal
                      </span>
                    </td>
                    <td> {{ reponse.created_at.split('T')[0] }} </td>
                    <td>
                      <button @click="afficherDetailsResponse(reponse)" class="btn btn-info btn-sm me-2 w-xs">
                        Détails
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

    </div>


    <div class="row">
      <!-- <div class="col-md-8">
        <l-map style="height: 400px; width: 100%" :zoom="zoom" :center="center">
          <l-tile-layer :url="url" :maxZoom="maxZoom" />
          <l-marker v-for="marker in markers" :key="marker.id" :lat-lng="marker.position" @click="position(marker.id)">
            <l-icon :icon-anchor="staticAnchor">
              <img src="@/assets/images/leaflet/marker-icon.png" />
            </l-icon>
          </l-marker>
        </l-map>
      </div> -->

    </div>

  </Layout>
</template>